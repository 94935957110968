import React, { useState, useEffect, useRef } from 'react';
import Chevron from './Chevron';

import styles from './accordion.module.css';

function Accordion(props) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState(styles.accordion__icon);

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? styles.accordion__icon : `${styles.accordion__icon} ${styles.rotate}`,
    );
  }

  return (
    <div className={styles.accordion__section} style={{width: '100%'}}>
      <button className={`${styles.accordion} ${setActive}`} onClick={toggleAccordion}>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <p style={{fontWeight: 600}} className={styles.accordion__title}>
            {`${props.title} - ` }
          </p>
          <pre
            style={props.percentage <= 33 ? { color: 'red', fontWeight: 600 }
              : (props.percentage >= 66 ? { color: 'green' , fontWeight: 600} : { color: '#ffd861', fontWeight: 600 })}
            className={styles.accordion__title}
          >
            {` ${props.percentage}`}
          </pre>
        </div>

        <Chevron className={`${setRotate}`} width={10} fill="#777" />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={styles.accordion__content}
      >
        <div
          className={styles.accordion__text}
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
}

export default Accordion;
