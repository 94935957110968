import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
import EmailEntry from '../views/EmailEntry';
import ResultsView from '../views/ResultsView';
import ResultsImages from '../views/ResultsImages';

const ResultsPage = () => {
  const [shouldShowResults, setShouldShowResults] = useState(false);
  const [upperEmail, setUpperEmail] = useState('');
  const [upperFirstName, setUpperFirstName] = useState('');
  const [upperLastName, setUpperLastName] = useState('');

  const { url } = useParams();
  // console.log('upper email', upperEmail);

  const toggleView = () => {
    setShouldShowResults(!shouldShowResults);
  };
  return (
    <div style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      {!shouldShowResults ? <EmailEntry setUpperEmail={setUpperEmail} setUpperFirstName={setUpperFirstName} setUpperLastName={setUpperLastName} toggleView={toggleView} />
        : <ResultsView email={upperEmail} firstName={upperFirstName} lastName={upperLastName}/>}

    </div>
  );
};

export default ResultsPage;
