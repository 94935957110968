import React, { useState, useEffect } from 'react';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { easeQuadInOut } from 'd3-ease';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import styles from './results.module.css';
import AnimatedProgressProvider from '../components/AnimatedProgressProvider';
import DonorViewCard from '../components/DonorViewCard';
import DonorViewMobileCard from '../components/DonorViewMobileCard';
import 'react-circular-progressbar/dist/styles.css';
import Accordion from '../components/Accordion/Accordion';
import LoadingAnimation from './Loading';
import dvImg from '../assets/images/dv.png';

const ResultsView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [donor, setDonor] = useState({});
  const [object, setObject] = useState({});
  const [finalGrade, setFinalGrade] = useState(0);
  const [security, setSecurity] = useState({});
  const [seo, setSeo] = useState({});
  const [speed, setSpeed] = useState({});
  const [cumulativeLayoutShift, setCumulativeLayoutShift] = useState({});
  const [interactionTime, setInteractionTime] = useState({});
  const [javascriptExecutionTime, setJavascriptExecutionTime] = useState({});
  const [images, setImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isSEOModalOpen, setIsSEOModalOpen] = useState(false);
  const [isSpeedModalOpen, setIsSpeedModalOpen] = useState(false);
  const [isJSModalOpen, setIsJSModalOpen] = useState(false);
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);

  const { url } = useParams();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 686px)' });

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    };
    async function getData() {
      // fetch(`http://localhost:5000/getSliders/${url}`)
      //   .then((res) => res.json())
      //   .then((result) => {
      //     console.log(result.images);
      //     console.log('jsTime', javascriptExecutionTime);
      //     setImages(result.images);
      //     setInteractionTime(result.interactionTime);
      //     setJavascriptExecutionTime(result.javascriptExecutionTime);
      //     setCumulativeLayoutShift(result.cumulativeLayoutShift);
      //     setSpeed(result.getSpeedIndex);
      //     setImagesLoaded(true);
      //   });

      // const data = await fetch(`localhost:5000/check-url/${url}`);

      fetch(`https://api.nonprofitseotool.com/getAllData/${url}/${props.email}/${props.firstName}/${props.lastName}`)
        .then((res) => res.json())
        .then(
          (result) => {
            setObject(result);
            setFinalGrade(result.url.finalGrade);
            setSecurity(result.url.security);
            setSeo(result.url.seo);
            setDonor(result.url.donor);
            setImages(result.grader.images);
            setInteractionTime(result.grader.interactionTime);
            setJavascriptExecutionTime(result.grader.javascriptExecutionTime);
            setCumulativeLayoutShift(result.grader.cumulativeLayoutShift);
            setSpeed(result.grader.getSpeedIndex);
            setImagesLoaded(true);
            setIsLoading(false);
          },
        );

      // fetch(`https://api.nonprofitseotool.com/check-url/${url}`)
      //   .then((res) => res.json())
      //   .then(
      //     (result) => {
      //       setObject(result);
      //       setFinalGrade(result.finalGrade);
      //       setSecurity(result.security);
      //       setSeo(result.seo);
      //       setDonor(result.donor);

      //       fetch(`https://api.nonprofitseotool.com/getSliders/${url}`)
      //         .then((res1) => res1.json())
      //         .then((result1) => {
      //           setImages(result1.images);
      //           setInteractionTime(result1.interactionTime);
      //           setJavascriptExecutionTime(result1.javascriptExecutionTime);
      //           setCumulativeLayoutShift(result1.cumulativeLayoutShift);
      //           setSpeed(result1.getSpeedIndex);
      //           setImagesLoaded(true);
      //           setIsLoading(false);
      //         });
      //     },
      //   );
    }
    getData();
  }, []);

  const Bar = styled.div`
  width: 100%;
  background-image: linear-gradient(90deg, #ff545d 33.33%, #ffd861 33.33%, #ffd861 66.66%, #B8F9D3 66.66%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0px;
  height: 20px;
  border-radius: 20px;
  `;

  if (!isLoading && imagesLoaded) {
    return (
      <div style={{
        maxWidth: '100vh',
        maxHeight: '100vh',
        // margin: 0,
        height: '100%',
        width: '100%',
      }}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative', marginBottom: 50,
        }}
        >
          <p style={{ color: 'white', fontWeight: 500, fontSize: 40 }}>Your Non Profit Report</p>
          <p style={{ color: 'white', fontSize: 30, marginTop: -40 }}><i>{url}</i></p>
        </div>
        <div style={{
          display: 'flex',
          position: 'relative',
          top: -15,
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          maxWidth: '100vh',
          maxHeight: '100vh',
          // margin: 0,
          height: '100%',
          width: '100%',
        // backgroundColor: 'white',
        }}
        >

          <a href="#" style={{ marginBottom: 20 }} className={`${styles.card} ${styles.greenCard}`}>
            <div className={styles.overlay} />
            <div style={{ zIndex: 10, width: '90%' }}>
              <A percentage={Math.round(seo.grade)} color="#B8F9D3" />
            </div>

            <p>SEO Score</p>
          </a>
          <a href="#" style={{ marginBottom: 30 }} className={`${styles.card} ${styles.blueCard}`}>
            <div className={styles.overlay} />
            <div style={{ zIndex: 10, width: '90%' }}>
              <A percentage={Math.round(security.grade)} color="#DCE9FF" />
            </div>

            <p>Security</p>
          </a>
          <a href="#" style={{ marginBottom: 30 }} className={`${styles.card} ${styles.yellowCard}`}>
            <div className={styles.overlay} />
            <div style={{ zIndex: 10, width: '90%' }}>
              <A percentage={Math.round(donor.grade)} color="#ffd861" />
            </div>

            <p>Donor Accessibility</p>
          </a>
          <div className={styles.card1} style={{ marginBottom: 30 }} style={{ marginTop: 60, height: 'auto', width: '90%' }}>
            <Accordion
              title="Search Engine Optimization"
              percentage={Math.round(seo.grade)}
              content={`

             <strong>Summary of Security</strong>
             </br>
             <ul>
               <li> ${seo.hasDeadLinks ? 'Page has dead Links - <b style="color: red;">BAD</b>' : ' Page has no dead links- <b style="color: #008000">GOOD</b>'}</li>
               <li>Page has enough keywords ${!seo.hasEnoughKeywords ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
               <li>Page has a title ${!seo.hasTitle ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
               <li>Images have alt text ${seo.imagesMissingAltText ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>


             </ul>

              ${seo.hasDeadLinks || !seo.hasEnoughKeywords
                || !seo.hasTitle || seo.imagesMissingAltText ? '<strong>Recommendations to Improve SEO (Search Engine Optimization)</strong>' : ''}
              </br>
              <ul>
                ${seo.hasDeadLinks ? '<li>Remove dead links or add meaningful content to the dead pages </li>' : ''}
                ${!seo.hasEnoughKeywords ? '<li>Add more keywords using the <code>meta</code> tags</li> in the code in order to make it easier for others to find your non profit' : ''}
                ${!seo.hasTitle ? '<li>Add a title using the <code>meta</code> tags in the code in order to allow search engines to properly display your page to potential members or donors</li>' : ''}
                ${seo.imagesMissingAltText ? '<li>Add an <code>alt</code> attribute to all images to make your page more accessible to users using screen readers</li>' : ''}
              </ul>
              `}
            />
            <Accordion
              title="Security"
              percentage={Math.round(security.grade)}
              content={`

             <strong>Summary of Security</strong>
              </br>
              <ul>
                <li>Page is Mobile Friendly ${!security.isMobileFriendly ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
                <li>Page has an SSL certificate ${!security.sslStatus ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
              </ul>

              ${!security.isMobileFriendly || !security.sslStatus ? '<strong>Recommendations to Improve Security</strong></br>' : ''}

              <ul>
                ${!security.isMobileFriendly ? '<li>Make your page mobile friendly</li>' : ''}
                ${!security.sslStatus ? '<li>Add an SSL certificate and setup https on your website</li>' : ''}
              </ul>
              `}
            />
            <Accordion
              title="Donor Accessibility"
              percentage={Math.round(donor.grade)}
              content={`
         <strong>Summary of Donor Accessibility</strong>
        </br>
        <ul>
          <li>Website has ample donation links ${!donor.donationLinks ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
          <li>Website has links to third party website for donations ${donor.linksToThirdParty ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
          <li>Website has ample membership links ${!donor.membershipLinks ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
          <li>Website has ample volunteer signup links ${!donor.volunteerLinks ? '- <b style="color: red;">BAD</b>' : ' - <b style="color: #008000">GOOD</b>'}</li>
        </ul>
        ${donor.linksToThirdParty || !donor.membershipLinks || !donor.volunteerLinks ? '<strong>Recommendations to Improve Donor Accessibility</strong>' : ''}
        </br>
        <ul>
          ${!donor.donationLinks ? '<li>Add more Donation links </li>' : ''}
          ${donor.linksToThirdParty ? '<li>Don\'t link to third party </li>' : ''}
          ${!donor.membershipLinks ? '<li>Add more membership links for your donors to find </li>' : ''}
          ${!donor.volunteerLinks ? '<li>Add more links and make them more accessible in order to make it easier to recruit volunteers </li>' : ''}
        </ul>
        `}
            />
          </div>

          <div className={styles.card2}>

            <div className="row" style={{ width: '90%' }}>
              <div
                className="column"
                style={{
                  position: 'relative', top: -20, width: '100%', backgroundColor: '#aaa;',
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  Cumulative Layout Shift
                  <a target="_blank" style={{ fontSize: 10 }} onClick={() => setIsSEOModalOpen(!isSEOModalOpen)}> (What is this)</a>
                  {isSEOModalOpen && (
                  <Rodal
                    visible={isSEOModalOpen}
                    customStyles={{ borderRadius: 20, height: '50%', width: `${!isTabletOrMobile ? '60vh' : '90%'}` }}
                    onClose={() => setIsSEOModalOpen(!isSEOModalOpen)}
                    animation="zoom"
                  >
                    <div>
                      <p style={{ fontSize: 20 }}>What is Cumulative Layout Shift (CLS)</p>
                      <p> Cumulative Layout Shift, or CLS as it is commonly referred to as, is the process of elements on your page shifting as the page loads. Without warning, a shift of the various elements of the page can be severely detrimental to the user experience of those visiting the websites. Additionally, CLS has unintended consequences of creating accidental button presses, in turn making it a crucial problem to manage, especially within payment forms. </p>
                      {/* <ul>
                        <li>dasjdsa</li>
                        <li>dasjdsa</li>
                        <li>dasjdsa</li>
                        <li>dasjdsa</li>
                        <li>dasjdsa</li>
                      </ul> */}
                    </div>
                  </Rodal>
                  )}
                </p>
                <Bar>
                  <i style={{ position: 'absolute', top: 30, left: `${cumulativeLayoutShift.percentage - 3}%` }} className={styles.pin} />
                </Bar>
              </div>
              <div
                className="column"
                style={{
                  width: '100%', position: 'relative', marginTop: -10, marginBottom: 10, backgroundColor: '#aaa;',
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  Page Speed
                  <a target="_blank" style={{ fontSize: 10 }} onClick={() => setIsSpeedModalOpen(!isSpeedModalOpen)}> (What is this)</a>
                  {isSpeedModalOpen && (
                  <Rodal
                    visible={isSpeedModalOpen}
                    customStyles={{ borderRadius: 20, height: '60%', width: `${!isTabletOrMobile ? '50vh' : '90%'}` }}
                    onClose={() => setIsSpeedModalOpen(!isSpeedModalOpen)}
                    animation="zoom"
                  >
                    <div>
                      <p style={{ fontSize: 20 }}>What is Page Speed</p>
                      <p>
                        Page Speed simply measures how quickly content visually appears. A page with a higher page speed score will have content show up earlier,
                        but also in increments to prevent content from loading in all at once at the detriment of speed. A faster loading website will have higher
                        retention from site visitors and will also allow your website to rank higher on search engines like Google
                        {' '}
                      </p>
                      <ul>
                        Quick Tips for Page Speed
                        <li>If possible, try and load things incrementally (e.g allow text to load in before the font)</li>
                        <li>Make sure that images are properly sized and formatted for the web</li>
                        <li>Allow the page to be interactive before things such as images have loaded in</li>
                      </ul>
                    </div>
                  </Rodal>
                  )}
                </p>
                <Bar>
                  <i style={{ position: 'absolute', top: 30, left: `${speed.percentage}%` }} className={styles.pin} />
                </Bar>
              </div>
              <div
                className="column"
                style={{
                  width: '100%', position: 'relative', marginBottom: 10, backgroundColor: '#aaa;',
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  Interaction Time
                  <a target="_blank" style={{ fontSize: 10 }} onClick={() => setIsTimeModalOpen(!isTimeModalOpen)}> (What is this)</a>
                  {isTimeModalOpen && (
                  <Rodal
                    visible={isTimeModalOpen}
                    customStyles={{ borderRadius: 20, height: '50%', width: `${!isTabletOrMobile ? '50vh' : '90%'}` }}
                    onClose={() => setIsTimeModalOpen(!isTimeModalOpen)}
                    animation="zoom"
                  >
                    <div>
                      <p style={{ fontSize: 20 }}>What is Interaction Time</p>
                      <p>
                        Interaction Time, or time to interactive (TTI) represents the time it takes for a
                        user that visits your website to actually interact with the page, such as through
                        clicking links, buttons, etc. This score is calculated by comparing the TTI for your webpage to other webpages.
                        Having a high TTI score is crucial in order to prevent frustration on the end of the user
                        which can result from pages that have loaded content but are not yet interactive.
                      </p>
                    </div>
                  </Rodal>
                  )}
                </p>
                <Bar>
                  <i style={{ position: 'absolute', top: 30, left: `${interactionTime.percentage}%` }} className={styles.pin} />
                </Bar>
              </div>
              <div
                className="column"
                style={{
                  width: '100%', position: 'relative', backgroundColor: '#aaa;',
                }}
              >
                <p style={{ marginBottom: 10 }}>
                  Javascript Execution Time
                  <a target="_blank" style={{ fontSize: 10 }} onClick={() => setIsJSModalOpen(!isJSModalOpen)}> (What is this)</a>
                  {isJSModalOpen && (
                  <Rodal
                    visible={isJSModalOpen}
                    customStyles={{ borderRadius: 20, height: '60%', width: `${!isTabletOrMobile ? '50vh' : '90%'}` }}
                    onClose={() => setIsJSModalOpen(!isJSModalOpen)}
                    animation="zoom"
                  >
                    <div>
                      <p style={{ fontSize: 20 }}>What is Javascript Execution Time</p>
                      <p>
                        Javascript execution time score is the representation of how quickly the javascript loads in.
                        Depending on your website, this may be vital in ensuring a good user experience for those visiting the webpage.
                        <br />
                        <br />
                        For more simple webpages, a poor javascript execution time score will result in a lackluster viewing experience, but
                        in larger, more elaborate websites that rely heavily on javascript, having a poor score for this category may impact
                        overall functionality of the website and may impact other metrics such as Time to Interactive
                      </p>
                    </div>
                  </Rodal>
                  )}
                </p>
                <Bar>
                  <i style={{ position: 'absolute', top: 30, left: `${javascriptExecutionTime.percentage}%` }} className={styles.pin} />
                </Bar>
              </div>
            </div>

            {/* <Bar /> */}
          </div>

          <div
            className={styles.card1}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              flexDirection: 'column',
              marginTop: 80,
              height: 'auto',
              width: '90%',
              paddingBottom: 30,
              backgroundColor: '#eee',
            }}
          >
            <p
              align="left"
              style={{
                alignSelf: 'flex-start', marginLeft: 25, fontSize: 40, marginTop: 20, textAlign: 'left',
              }}
            >
              Page Load
            </p>
            <p
              align="left"
              style={{
                fontWeight: 100, alignSelf: 'flex-start', marginLeft: 25, marginTop: -40, fontSize: 20, textAlign: 'left',
              }}
            >
              See how your page loads over time (in milliseconds)
            </p>
            <div style={{
              display: 'flex',
              justifyContent: 'space-around',
              // alignItems: 'center',
              flexWrap: 'wrap',
              flexDirection: 'row',
              position: 'relative',
              top: 10,
              height: 'auto',
              width: '100%',
              backgroundColor: '#eee',
            }}
            >
              {images ? (images.map((el) => (
                <div style={{ verticalAlign: 'top', textAlign: 'center', display: 'inline-block' }}>
                  <figure style={{
                    display: 'inline-block',
                    height: '100%',
                    verticalAlign: 'middle',
                    overflow: 'auto',
                    padding: 20,
                  }}
                  >
                    <img src={`data:image/jpeg;base64, ${el.data}`} alt={`${el.timing} - ${el.timestamp}`} style={{ height: 100, width: 100, backgroundColor: 'red' }} />
                    <span style={{ display: 'block' }}>
                      {el.timing}
                      {' '}
                      msec
                    </span>
                  </figure>
                </div>
              ))) : console.log('loading')}
            </div>
          </div>
          {!isTabletOrMobile ? <DonorViewCard /> : <DonorViewMobileCard />}
          <div className={styles.spacerCard} />

        </div>
        <div style={{ height: 50 }} />
      </div>
    );
  }
  return (
    <div style={{ overflow: 'hidden', height: '100%' }}>
      <LoadingAnimation />
    </div>

  );
};

const A = (props) => (
  <AnimatedProgressProvider
    valueStart={0}
    valueEnd={props.percentage}
    duration={1.4}
    easingFunction={easeQuadInOut}
  >
    {(value) => {
      const roundedValue = Math.round(value);
      return (
        <CircularProgressbar
          value={value}
          background
          backgroundPadding={6}
          text={`${roundedValue}%`}
          styles={buildStyles({
            pathTransition: 'none',
            backgroundColor: props.color,
            textColor: 'black',
            pathColor: '#fff',
            trailColor: 'transparent',
          })}
        />
      );
    }}
  </AnimatedProgressProvider>

);
export default ResultsView;
