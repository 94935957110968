import React, { useState, createRef } from 'react';
import './input.css';

export default class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || '',
      error: props.error || '',
      label: props.label || 'Label',
    };
  }

  changeValue(event) {
    // this.props.handleChange();
    const { value } = event.target;
    this.setState({ value, error: '' });
  }

  handleKeyPress(event) {
    if (event.which === 13) {
      this.setState({ value: this.props.predicted });
    }
  }

  render() {
    const {
      active, value, error, label,
    } = this.state;
    const { predicted, locked, handleChange } = this.props;
    const fieldClassName = `field ${(locked ? active : active || value)
      && 'active'} ${locked && !active && 'locked'}`;

    return (
      <div className={fieldClassName}>
        {active && value && predicted && predicted.includes(value) && (
          <p className="predicted">{predicted}</p>
        )}
        <input
          id={1}
          type="text"
          value={this.props.value}
          placeholder={label}
          onChange={handleChange}
          onKeyPress={this.handleKeyPress.bind(this)}
          onFocus={() => !locked && this.setState({ active: true })}
          onBlur={() => !locked && this.setState({ active: false })}
        />
        <label htmlFor={1} className={error && 'error'}>
          {error || label}
        </label>
      </div>
    );
  }
}
