import React, { useState, createRef } from 'react';
import SearchBar from '../components/Search/SearchBar';
import styles from '../components/Search/search.module.css';
import Input from '../components/Input/Input';
import homeImg from '../assets/images/home.svg';

const Home = () => {
  const [url, setUrl] = useState('');
  const input = createRef();
  const [active, setActive] = useState(false);
  const [locked, setLocked] = useState(false);
  const [predicted, setPredicted] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newUrl = url.replace(/^https?\:\/\//i, '');
    setUrl(newUrl);
    window.location.href = `/results/${newUrl}`;
  };

  const fieldClassName = `field ${(locked ? active : active || url)
    && 'active'} ${locked && !active && 'locked'}`;

  return (
    <div style={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', marginTop: 250,
    }}
    >
      <div className="topLogo">
        <a style={{ color: 'white' }} href="https://donorview.com">
          Powered By
          <span> Donor</span>
          <span>View</span>
        </a>
      </div>

      <div style={{
        display: 'flex', flexDirection: 'column', position: 'relative', alignItems: 'center', justifyContent: 'center', width: 640, height: 300,
      }}
      >
        <img
          style={{
            width: '90%', height: '90%', position: 'relative', bottom: 200,
          }}
          src={homeImg}
        />

        {/* <SearchBar /> */}
        <form
          autoComplete="off"
          onSubmit={(e) => handleSubmit(e)}
          style={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            textAlign: 'center',
            width: '100%',
          // height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
        >
          <p style={{
            fontWeight: 500, fontSize: 40, color: 'white', marginBottom: -10,
          }}
          >
            NonprofitSeoTool.com
          </p>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row',
          }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={fieldClassName}>
              {active && url && predicted && predicted.includes(url) && (
              <p className="predicted">{predicted}</p>
              )}
              <input
                id={1}
                type="text"
                value={url}
                placeholder="Enter URL"
                onChange={handleChange}
                style={{}}
              // onKeyPress={this.handleKeyPress.bind(this)}
                onFocus={() => !locked && setActive(true)}
                onBlur={() => !locked && setActive(false)}
              />

              <label htmlFor={1} className={error && 'error'}>
                {error || 'Enter URL'}
              </label>

            </div>
            <button
              style={{
                height: 56,
                width: 56,
                position: 'relative',
                left: 10,
                marginRight: 0,
                paddingTop: 0,
                backgroundColor: 'rgba(255,255,255,0.8)',
                marginTop: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                verticalAlign: 'middle',
                padding: 0,
              }}
              onClick={(e) => handleSubmit(e)}
            >
              <i style={{ color: 'black' }} className="gg-search" />
            </button>
          </div>
        </form>
      </div>
      <div className="footer">
        <p style={{ color: 'white' }}>
          <span>&copy;</span>
          {' '}
          {`${new Date().getFullYear()} All Rights Reserved. ConnectedView One Research Drive, Suite 310B Westborough, MA 01581`}
        </p>
      </div>
    </div>
  );
};

export default Home;
