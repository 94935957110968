import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
import styles from './emailEntry.module.css';

export const EmailForm = (props) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { url } = useParams();

  const handleSubmit = (event) => {
    // make post request
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({}),
    };

    fetch(`https://api.nonprofitseotool.com/submit-email/${email}/${firstName}/${lastName}/${url}`).then(
      (res) => res.json(),
    ).then(
      (response) => {
        if (response.success) {
          props.toggleView();
        }
      },
    );

    event.preventDefault();
  };

  return (
    <form style={{ borderRadius: 15, marginTop: 100, backgroundColor: 'rgba(255, 255, 255, .55)' }} className={styles.a} onSubmit={handleSubmit}>
      <h1 style={{ opacity: 1 }}> Get Results</h1>

      <label>
        Email:
        <input
          name="email"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}
          type="email"
          value={email}
          onChange={(e) => {
            props.setUpperEmail(e.target.value);
            setEmail(e.target.value);
          }}
          required
        />
      </label>

      <label>
        First Name:
        <input
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}
          name="name"
          value={firstName}
          onChange={(e) => {
            props.setUpperFirstName(e.target.value);
            setFirstName(e.target.value);
          }}
          required
        />
      </label>

      <label>
        Last Name:
        <input
          name="name"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}
          value={lastName}
          onChange={
            (e) => {
              setLastName(e.target.value);
              props.setUpperLastName(e.target.value);
            }
          }
          required
        />
      </label>
      <button
        style={{
          backgroundColor: '#b8def9',
          cursor: 'pointer',
          alignSelf: 'center',
          borderRadius: 10,
          width: '90%',
          marginTop: 25,
          color: 'gray',
          fontSize: 20,
          padding: 10,
          fontWeight: 'bold',
        }}
        type="submit"
      >
        Submit
      </button>
      <div style={{
        position: 'relative', top: 20, left: '40%', fontSize: 20,
      }}
      >
        <a style={{ color: 'white' }} onClick={() => window.open('https://donorview.com')}>
          {' '}
          Powered By
          <span style={{ color: '#f6941b' }}> Donor</span>
          <span style={{ color: '#61bbe6' }}>View</span>
        </a>
      </div>
      <div style={{
        position: 'relative', top: 20, alignSelf: 'center', color: 'white', textAlign: 'center',
      }}
      >
        <i>We respect your privacy. Your information will not be shared with any 3rd party.</i>
      </div>
    </form>
  );
};

export default EmailForm;
