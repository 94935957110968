import React from 'react';
import styles from '../views/results.module.css';
import dvImg from '../assets/images/dv.png';

export default function DonorViewCard() {
  return (
    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }} className={styles.actionCard}>
      <div style={{
        width: '100%', height: '50%', display: 'flex', flexDirection: 'row',
      }}
      >

        <img src={dvImg} style={{ width: 'auto', height: '70%', display: 'flex' }} />

        <p
          align="left"
          style={{
            textAlign: 'center',
            fontSize: 21,
            padding: 10,
          }}
        >
          Looking to improve your donor management system?
          <br />
          <strong>
            Try
            {' '}
            <span style={{ color: '#f6941b' }}>Donor</span>
            <span style={{ color: '#61bbe6' }}>View</span>
          </strong>
        </p>
      </div>

      <div style={{
        width: '90%', height: '50%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row',
        marginTop: -60,
      }}
      >
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%', height: '50%',

        }}
        >
          <button

            onClick={() => window.open('https://www.donorview.com/request-a-demo/')}
            style={{
              lineHight: '50%', fontSize: 15, borderRadius: 15, background: '#f6941b', width: '80%', height: '80%',
            }}
            title="one"
          >
            Request a Demo

          </button>
        </div>

        <button
          onClick={() => window.open('https://donorview.com')}
          style={{
            borderRadius: 15, backgroundColor: '#61bbe6', width: '60%', height: '40%',
          }}
          title="one"
        >
          Visit Website
        </button>
      </div>
      <p align="center" style={{fontSize: 15, marginTop: -20, width: '80%', height: '15%'}}>
        All DonorView customers get
        <strong> free</strong>
        {' '}
        tailored recommendations from our SEO experts
      </p>
    </div>
  );
}
