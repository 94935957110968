import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
import logo from './logo.svg';
import Home from './pages/Home';
import ResultsPage from './pages/ResultsPage';

function App() {
  return (
    <Router>
      <div className="full">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/results/:url">
            <ResultsPage />
          </Route>
        </Switch>
      </div>
    </Router>

  );
}

export default App;
