import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/loading.json';

export default class LoadingAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const buttonStyle = {
      display: 'block',
      margin: '10px auto',
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
        />
        <p align="center" style={{ fontWeight: 500, fontSize: 30, color: 'white' }}>Fetching Data from Your Website...</p>
      </div>
    );
  }
}
